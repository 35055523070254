import { useEffect } from "react";
import { CustomerAuthState } from "src/apps/customer/AuthProvider";
import { setupPermissionDeniedInterceptor } from "src/utils/api";

type AxiosInterceptorProps = {
  useAuthHook: () => CustomerAuthState | null;
};

const InjectAxiosInterceptors = ({ useAuthHook }: AxiosInterceptorProps) => {
  const { cleanupAfterLogout } = useAuthHook() as CustomerAuthState;

  useEffect(() => {
    // If the user receives HTTP 403 from the api then
    // they are no longer authenticated. Perform some "cleanup"
    // logic to reset the `AuthProvider`
    const handlePermissionDenied = async () => {
      await cleanupAfterLogout();
    };

    setupPermissionDeniedInterceptor(handlePermissionDenied);
  }, [cleanupAfterLogout]);

  return null;
};

export default InjectAxiosInterceptors;
